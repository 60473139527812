export const GET_VOTERS = "GET_VOTERS";
export const VOTER_ERROR = "VOTER_ERROR";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const GET_USERS = "GET_USERS";
export const USER_ERROR = "USER_ERROR";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_ERROR = "UPDATE_ERROR";